import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert'; // Import

import PropTypes from 'prop-types';
import menu from 'components/menu/datas/menuRight.json';
import Toastr from 'modern-toastr';
import { Icon } from 'semantic-ui-react';
import * as actions from 'actions';
import { Icon as AntdIcon, Button, Menu, Dropdown } from 'antd';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './TopRightMenu.css';

const companyInfomation = (
	<Menu className="p-2">
		<Menu.Item>
			<Link to="/views/company">보유 지식</Link>
		</Menu.Item>
		<Menu.Item>
			<Link to="/views/company/history">회사 연혁</Link>
		</Menu.Item>
		<Menu.Item>
			<Link to="/views/company/map">회사 위치</Link>
		</Menu.Item>
	</Menu>
);

let mapStateToProps = (state) => {
	return {
		authenticated: state.auth.authenticated,
		user: state.auth.user,
	};
};

const mapDispatchToProps = (dispatch) => ({
	logout: () => dispatch(actions.logout()),
});

class TopRightMenu extends Component {
	constructor(props) {
		super(props);

		const USER = JSON.parse(window.localStorage.getItem('user'));
		let grade = null;
		if (props.user && !USER) {
			window.localStorage.setItem('user', JSON.stringify(props.user));
			grade = props.user.grade;
		} else if (USER) {
			grade = USER.grade;
		}

		this.state = {
			menus: menu.menus,
			grade,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.authenticated !== prevProps.authenticated) {
			let grade = null;
			if (this.props.authenticated) {
				grade = this.props.user.grade;
			}
			this.setState({ grade });
		}

		return true;
	}

	onClick(e, menuItem) {
		switch (menuItem.eventCode) {
			case 'LOGOUT':
				e.preventDefault();
				confirmAlert({
					customUI: ({ onClose }) => {
						return (
							<div className="logout-custom-ui">
								<h3>
									<AntdIcon type="logout" />
									로그아웃
								</h3>
								<p>정말 로그아웃 하시겠습니까?</p>
								<div className="logout-btns">
									<Button onClick={onClose}>머무르기</Button>
									<Button
										type="primary"
										onClick={(e) => {
											e.preventDefault();

											window.sessionStorage.removeItem('user');
											window.localStorage.removeItem('user');
											window.parent.postMessage({ loginStatus: false }, '*');

											this.props.logout();
											this.props.history.push('/');
											onClose();
										}}>
										로그아웃
									</Button>
								</div>
							</div>
						);
					},
				});
				break;
			case 'MYPAGE':
				this.setState(this.props.handleClick(e, 5));
				this.props.history.push({
					pathname: menuItem.path,
					hash: menuItem.hash,
				});
				break;
			case 'GROUPS':
				this.setState(this.props.handleClick(e, 6));
				this.props.history.push({
					pathname: menuItem.path,
					hash: menuItem.hash,
				});
				break;
			case 'CUSTOMCENTER':
				this.setState(this.props.handleClick(e, 7));
				this.props.history.push({
					pathname: menuItem.path,
					hash: menuItem.hash,
				});
				break;
			case 'COMPANY':
				this.setState(this.props.handleClick(e, 8));
				this.props.history.push({
					pathname: menuItem.path,
					hash: menuItem.hash,
				});
				break;
			case 'ADMIN':
				this.setState(this.props.handleClick(e, 9));
				this.props.history.push({
					pathname: menuItem.path,
					hash: menuItem.hash,
				});
				break;
			case 'LOGIN':
				e.preventDefault();
				this.props.history.push({
					pathname: menuItem.path,
					hash: menuItem.hash,
					redirectUrl: this.props.location.pathname,
					state: { from: this.props.location },
				});
				break;
			case 'JOIN':
				e.preventDefault();
				this.props.history.push({
					pathname: menuItem.path,
					hash: menuItem.hash,
					redirectUrl: this.props.location.pathname,
					state: { from: this.props.location },
				});
				break;
			case 'MINI_DIC': {
				e.preventDefault();

				const user = this.props.user;
				if (user !== undefined && user !== null && user.status === 'valid') {
					var url = menuItem.path + '?linkType=MAIN&id=' + user.id + '&email=' + user.userEmail;
					window.open(
						url,
						'낱말창고-꼬마사전',
						'location=0, scrollbars=1, menubar=0, resizable=0, status=0, toolbar=0, width=500, height=800'
					);
				} else {
					Toastr.error('꼬마사전은 유료회원 전용 서비스입니다.');
				}

				break;
			}
			case 'BOOKMARK': {
				e.preventDefault();
				//var title = "Lael's World";
				//var url = "https://blog.lael.be";
				const title = document.title; //현재 보고 있는 페이지의 Title
				const url = window.location.href; //현재 보고 있는 페이지의 Url
				if (window.sidebar && window.sidebar.addPanel) {
					//firefox
					window.sidebar.addPanel(title, url, '');
				} else if (window.opera && window.print) {
					//opera
					var elem = document.createElement('a');
					elem.setAttribute('href', url);
					elem.setAttribute('title', title);
					elem.setAttribute('rel', 'sidebar');
					elem.click();
				} else if (document.all) {
					//msie
					window.external.AddFavorite(url, title);
				} else {
					alert('해당브라우저는 즐겨찾기 추가기능이 지원되지 않습니다.\n\n수동으로 즐겨찾기에 추가해주세요.');
				}
				break;
			}
			default:
				e.preventDefault();
				//	this.props.history.push({ pathname: menuItem.path, hash: menuItem.hash });
				break;
		}
	}

	render() {
		const { menus, grade } = this.state;
		const { authenticated } = this.props;
		return (
			<Fragment>
				{menus.map((menuItem, index) => {
					const color = menuItem.isActive ? 'color-warning' : 'color-dark';
					if (menuItem.text === '회사소개') {
						return (
							<span key={'top_' + index}>
								<Dropdown overlay={companyInfomation}>
									<small
										className="company-infomation"
										onClick={(e) => {
											e.preventDefault();
											this.props.history.push({
												pathname: '/views/company',
											});
										}}>
										회사소개
										<DownOutlined />
									</small>
								</Dropdown>
								&nbsp;<span className="color-medium">|</span>&nbsp;
							</span>
						);
					}
					if (menuItem.nologin === authenticated && grade <= menuItem.grade) {
						return (
							<Link
								key={'top_' + index}
								to={{ pathname: menuItem.path, hash: menuItem.hash }}
								//className={color + ' animated zoomInDown animation-delay-' + (index + 1) * 2}
								className={color}
								onClick={(e) => this.onClick(e, menuItem)}>
								{menuItem.isActive ? (
									<small className="font-weight-bold">
										{menuItem.text}
										{index < menus.length - 1 && (
											<Fragment>
												&nbsp;<span className="color-medium">|</span>&nbsp;
											</Fragment>
										)}
									</small>
								) : (
									<small>
										{menuItem.text}
										{index < menus.length - 1 && (
											<Fragment>
												&nbsp;<span className="color-medium">|</span>&nbsp;
											</Fragment>
										)}
									</small>
								)}
							</Link>
						);
					} else if (menuItem.nologin == null) {
						return (
							<Link
								key={'top_' + index}
								to={{ pathname: menuItem.path, hash: menuItem.hash }}
								//className={color + ' animated zoomInDown animation-delay-' + (index + 1) * 2}
								className={color}
								onClick={(e) => this.onClick(e, menuItem)}>
								{menuItem.isActive ? (
									<small className="font-weight-bold">
										{menuItem.text}
										{index < menus.length - 1 && (
											<Fragment>
												&nbsp;<span className="color-medium">|</span>&nbsp;
											</Fragment>
										)}
									</small>
								) : (
									<small>
										{menuItem.text}
										{index < menus.length - 1 && (
											<Fragment>
												&nbsp;<span className="color-medium">|</span>&nbsp;
											</Fragment>
										)}
									</small>
								)}
							</Link>
						);
					}
					return '';
				})}
			</Fragment>
		);
	}
}

TopRightMenu.propTypes = {
  authenticated: PropTypes.bool,
  handleClick: PropTypes.func,
};

TopRightMenu.defaultProps = {
  authenticated: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TopRightMenu));
